a,
i,
span {
  display: inline-block;
  text-decoration: none;
}

a:hover,
a:focus,
i:hover,
i:focus,
span:hover,
span:focus {
  text-decoration: none;
  outline: none;
}

ul {
  margin: 0;
  padding: 0;
}

section {
  background-color: #fff;
  // background-color: rgba(250, 250, 250, 1);
  height: 100%;
}

canvas {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

header.page-header {
  padding: 20px 0;
  h2 {
    font-size: 1rem;
  }
}

.bg-mystic {
  background-color: #e1e5ee !important;
}

.table {
  font-size: 0.9rem;
  color: $gray-600;
  th,
  td {
    padding: 0.75rem;
    vertical-align: middle;
    border-top: 1px solid $gray-300;
  }
  &.table-striped thead tr {
    background-color: $gray-200;
    th {
      font-weight: 600;
      color: $gray-900;
    }
  }
  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: transparentize($rgba-secondary-lighter, 0.9);
  }
  &.table-hover tbody tr:hover {
    background-color: transparentize($rgba-accent-lighter, 0.9);
  }
}

// .realtime-list, .missions-list, .places-list, .zones-list {
//   background: #f3f3f3;
// }

#placeList .selected-row,
#realtimeList .selected-row {
  border-left: 2px solid $primary-color;
  background: rgba(95, 70, 92, 0.1);
}
.card-placesList {
  width: 100% !important;
  &__body {
    overflow-y: hidden;
  }
  &__title {
    font-weight: bold;
  }
}
.places-row {
  max-height: calc(100vh - 290px);
  padding: 0.5rem;
  cursor: pointer;
}
// .realtime-row, .missions-row, .places-row, .zones-row {
//   padding: 1rem;
//   cursor: pointer;
//   background: #fff;
//   border-radius: 5px;
//   border: 1px solid rgba(0, 0, 0, 0.1);
//   box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1);
// }
.content-inner {
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding-bottom: 60px;
  background-color: #fff;
}

.content-inner.active {
  width: calc(100% - 120px);
}

#account-section {
  height: calc(100vh - 94px);
  overflow-y: scroll;
}

.page-header {
  background: #fff;
  padding: 15px;
  box-shadow: 2px 0px 3px #d3d0dc;
  position: relative;
  z-index: 999;
}

*[class*='icon-'] {
  -webkit-transform: translateY(3px);
  transform: translateY(3px);
}
.showMore {
  margin: 0 auto;
  font-size: 0.9rem;
  a {
    color: #1a73e8 !important;
  }
}
button,
input {
  outline: none !important;
}
.place-row {
  cursor: pointer;
}
.place-card {
  font-size: 0.875rem;
  font-weight: 300;
}
.place-name {
  font-size: 1rem;
}
// .card-map {
//   position: fixed;
//   width: 100% !important;
//   border: none !important;
//   z-index: 1001;
//   @include media-breakpoint-up(lg) {
//     width: 33% !important;
//   }
// }
.card {
  margin-bottom: 10px;
  // border: 1px solid rgba(0, 0, 0, 0.07);

  .card-header {
    box-shadow: none;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: 0;
      display: flex;
      flex-wrap: nowrap;
    }
  }
  .card-body {
    padding: 15px;
    .card-organization-list {
      list-style: none;
      > li {
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
        a {
          color: #686d71;
          font-size: 0.8rem;
          transition: none !important;
          &:hover {
            color: $accent-color-lighter;
          }
        }
      }
    }
  }
  .card-footer {
    padding-top: ($card-spacer-y * 1.125);
    padding-bottom: ($card-spacer-y * 1.125);
    padding-left: ($card-spacer-x * 1.125);
    padding-right: ($card-spacer-x * 1.125);
    background: lighten($gray-100, 0%);
    ul {
      list-style: none;
      > li {
        margin-right: 10px;
        &:last-of-type {
          margin-right: 0;
        }
        a {
          color: $accent-color;
          font-size: 0.8rem;
          transition: none !important;
          &:hover {
            color: $accent-color-lighter;
          }
        }
      }
    }
  }
  .card-overlay {
    width: 100%;
    height: 100%;
    position: absolute;
    background: transparentize($gray-100, 0.1);
    opacity: 0;
    z-index: -1;
    transition: opacity 0.3s ease-in;
    &.card-overlay-active {
      opacity: 1;
      z-index: 1;
    }
  }

  .card--vehicle {
    .card-body {
      min-height: 220px;
    }
  }
  .card--account {
    margin-bottom: 30px;
  }
}

.breadcrumb-holder {
  background: $white;
}

.breadcrumb {
  background: none !important;
  // position: relative;
  // z-index: 7;
  // border-radius: 0;
  // padding: 0;
  //margin: 10px 0;
  // bottom: 10px;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '\203A';
}

.breadcrumb li.breadcrumb-item {
  font-size: 0.9rem;
  font-weight: 400;
  a {
    color: $primary-color-light;
    &:hover {
      color: $secondary-color-darker;
    }
  }
  &.active {
    color: $secondary-color;
  }
}

/*=== Helpers ===*/

.text-bold {
  font-weight: 700;
}
.text-semibold {
  font-weight: 600;
}
.text-thin {
  font-weight: 300;
}

.text-small {
  font-size: 0.9rem;
}

.text-large {
  font-size: 1.4rem;
}

.text-xlarge {
  font-size: 1.6rem;
}

.text-xxlarge {
  font-size: 2rem;
}

.bg-primary {
  background-color: $primary-color !important;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: $primary-color-lighter !important;
}

.bg-primary-light {
  background-color: $primary-color-light !important;
}

.bg-primary-lighter {
  background-color: $primary-color-lighter !important;
}

.bg-primary-dark {
  background-color: $primary-color-dark !important;
}

.bg-primary-darker {
  background-color: $primary-color-darker !important;
}

.bg-secondary {
  background-color: $secondary-color !important;
}

.bg-secondary-light {
  background-color: $secondary-color-light !important;
}

.bg-secondary-lighter {
  background-color: $secondary-color-lighter !important;
}

.bg-secondary-dark {
  background-color: $secondary-color-dark !important;
}

.bg-secondary-darker {
  background-color: $secondary-color-darker !important;
}

a.bg-secondary:focus,
a.bg-secondary:hover {
  background-color: $secondary-color-lighter !important;
}

.bg-accent {
  background-color: $accent-color-light !important;
}

.bg-accent-light {
  background-color: $accent-color-light !important;
}

.bg-accent-lighter {
  background-color: $accent-color-lighter !important;
}

.bg-accent-dark {
  background-color: $accent-color-dark !important;
}

.bg-accent-darker {
  background-color: $accent-color-darker !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: $primary-color-lighter !important;
}

.bg-success {
  background-color: #28a745 !important;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #1e7e34 !important;
}

.bg-info {
  background-color: #17a2b8 !important;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #117a8b !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #d39e00 !important;
}

.bg-danger {
  background-color: $red !important;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: $red !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

a.bg-light:focus,
a.bg-light:hover {
  background-color: #dae0e5 !important;
}

.bg-dark {
  background-color: #343a40 !important;
}

a.bg-dark:focus,
a.bg-dark:hover {
  background-color: #1d2124 !important;
}

.bg-red {
  background: #ff7676 !important;
  color: #fff;
}

.bg-red:hover {
  color: #fff;
}

.bg-green {
  background: #54e69d !important;
  color: #fff;
}

.bg-green:hover {
  color: #fff;
}

.bg-gray-100 {
  background: $gray-100 !important;
}

.bg-gray-200 {
  background: $gray-200 !important;
}

.bg-gray-300 {
  background: $gray-300 !important;
}

.bg-gray-400 {
  background: $gray-400 !important;
}

.bg-gray-500 {
  background: $gray-500 !important;
}

.bg-gray-600 {
  background: $gray-600 !important;
}

.bg-gray-700 {
  background: $gray-700 !important;
}

.bg-gray-800 {
  background: $gray-800 !important;
}

.bg-gray-900 {
  background: $gray-900 !important;
}

.bg-white {
  background: $white !important;
}

.text-red {
  color: #ff7676;
}

.text-red:hover {
  color: #ff7676;
}

.text-yellow {
  color: #eef157;
}

.text-yellow:hover {
  color: #eef157;
}

.text-blue {
  color: #286df9;
}

.text-blue:hover {
  color: #83b2fc;
}

.text-green {
  color: #54e69d;
}

.text-green:hover {
  color: #54e69d;
}

.text-gray {
  color: $gray-200;
}

.text-gray:hover {
  color: $gray-200;
}

.text-gray-100 {
  color: $gray-100 !important;
}

.text-gray-200 {
  color: $gray-200 !important;
}

.text-gray-300 {
  color: $gray-300 !important;
}

.text-gray-400 {
  color: $gray-400 !important;
  &:hover,
  &:focus {
    color: $gray-600 !important;
  }
}

.text-gray-500 {
  color: $gray-500 !important;
}

.text-gray-600 {
  color: $gray-600 !important;
}

.text-gray-700 {
  color: $gray-700 !important;
}

.text-primary {
  color: $primary-color !important;
}

.text-primary-light {
  color: $primary-color-light !important;
}

.text-primary-lighter {
  color: $primary-color-lighter !important;
}

.text-primary-dark {
  color: $primary-color-dark !important;
}

.text-primary-darker {
  color: $primary-color-darker !important;
}

.text-secondary {
  color: $secondary-color !important;
}

.text-secondary-light {
  color: $secondary-color-light !important;
}

.text-secondary-lighter {
  color: $secondary-color-lighter !important;
}

.text-secondary-dark {
  color: $secondary-color-dark !important;
}

.text-secondary-darker {
  color: $secondary-color-darker !important;
}

.text-accent {
  color: $accent-color !important;
}

.text-accent-light {
  color: $accent-color-light !important;
}

.text-accent-lighter {
  color: $accent-color-lighter !important;
}

.text-accent-dark {
  color: $accent-color-dark !important;
}

.text-accent-darker {
  color: $accent-color-darker !important;
}

a.text-primary:focus,
a.text-primary:hover {
  color: $accent-color-lighter;
}

a.text-primary:focus,
a.text-primary:hover {
  color: $accent-color-lighter;
}

a.text-secondary:focus,
a.text-secondary:hover {
  color: $secondary-color-lighter !important;
}

a.text-gray-100:hover,
a.text-gray-100:focus {
  color: $gray-300 !important;
}
a.text-gray-200:hover,
a.text-gray-200:focus {
  color: $gray-400 !important;
}
a.text-gray-300:hover,
a.text-gray-300:focus {
  color: $gray-500 !important;
}
a.text-gray-400:hover,
a.text-gray-400:focus {
  color: $gray-600 !important;
}
a.text-gray-500:hover,
a.text-gray-500:focus {
  color: $gray-700 !important;
}
a.text-gray-600:hover,
a.text-gray-600:focus {
  color: $gray-800 !important;
}
a.text-gray-700:hover,
a.text-gray-700:focus {
  color: $gray-900 !important;
}

.text-line-through {
  text-decoration: line-through !important;
}

.page {
  background: $gray-100;
}

.page .text-white {
  color: $white;
}

.no-padding {
  padding: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-margin-top {
  margin-top: 0 !important;
}

.page {
  overflow: hidden;
}

.has-shadow {
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1),
    -1px 0 2px rgba(0, 0, 0, 0.05);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -1px 0 2px rgba(0, 0, 0, 0.05);
}

.has-border {
  border: 1px solid #ced4da;
}

.badge {
  position: relative;
  font-weight: 300;
  font-size: 0.675rem;
  padding: 0.2rem 0.2rem;
  top: -2px;
  &--notop {
    top: 0;
  }
}

sup.badge {
  left: 5px;
}

.badge-rounded {
  border-radius: 50px;
}

.pace {
  pointer-events: none;
  user-select: none;
}

.pace-inactive {
  display: none;
}

.pace .pace-progress {
  background: #000000;
  position: fixed;
  z-index: 2000;
  top: 0;
  right: 100%;
  width: 100%;
  height: 2px;
}

#simple-progress {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  pointer-events: none;
  user-select: none;
  background: transparent url(../img/bg-preloader.png) repeat 0 0;
  position: fixed;
  z-index: 999999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  font-size: 1.75rem;
  color: $primary-color-darker;
  opacity: 0.6;
  &::after {
    content: 'atseeone is loading...';
    font-size: 0.9rem;
    color: $primary-color-darker;
    position: absolute;
    display: inline-block;
    text-align: left;
    width: 150px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 1;
    animation: loading-anim 2s linear infinite alternate;
    @include media-breakpoint-up(lg) {
    }
  }
}
@keyframes loading-anim {
  0% {
    content: 'atseeone is loading';
  }
  10% {
    content: 'atseeone is loading.';
  }
  20% {
    content: 'atseeone is loading..';
  }
  30% {
    content: 'atseeone is loading...';
  }
  40% {
    content: 'atseeone is loading....';
  }
  50% {
    content: 'atseeone is loading';
  }
  60% {
    content: 'atseeone is loading.';
  }
  70% {
    content: 'atseeone is loading..';
  }
  80% {
    content: 'atseeone is loading...';
  }
  90% {
    content: 'atseeone is loading....';
  }
  100% {
    content: 'atseeone is loading.....';
  }
}
.v-select .dropdown-toggle {
  border: 1px solid $gray-400 !important;
  border-radius: 0 !important;
}

.v-select.is-invalid .dropdown-toggle {
  border: 1px solid $red !important;
}
/* ---- vdp-datepicker ---- */
.vdp-datepicker {
  .form-control {
    border: 1px solid rgba(0, 0, 0, 0.07) !important;
    height: 38px !important;
  }
  &.is-invalid {
    .form-control {
      border: 1px solid $red !important;
    }
  }
}
.missions-list {
  #startDate-input {
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: 0 !important;
  }
  #endDate-input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
}
.vdp-datepicker__input {
  &:focus {
    border: 1px solid $gray-400 !important;
  }
}
#report-alarm .vdp-datepicker__input {
  padding: 5px 8px;
  height: 33px;
}

.vdp-datepicker__clear-button,
.vdp-datepicker__calendar-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  cursor: pointer;
  font-style: normal;
  text-align: center;
  outline: none !important;
}
.vdp-datepicker__calendar-button {
  width: 30px;
}

.nav-pills .nav-link.active,
.nav-link.hover,
.nav-pills .show > .nav-link {
  color: #5f465c;
  font-weight: 400;
  margin-left: 2px;
  background-color: #f5f5f5 !important;
  font-weight: 400;
}

.nav-pills .nav-link {
  color: $gray-600;
  font-weight: 300;
  font-size: 0.975rem;
  border-radius: 0;
  color: #343a40;
}

// .list-group-item {
// position: relative;
// display: block;
// padding: 0.375rem 0;
// margin-bottom: -1px;
// background-color: #fff;
// border: 0;
// font-size: 0.775rem;
// }
// .list-group-item:first-child {
//   border-top-left-radius: 0;
//   border-top-right-radius: 0;
// }
// .list-group-item.active {
//   z-index: 2;
//   color: #fff;
//   background-color: $gray-200;
//   border-color: $gray-300;
// }
// .list-group-item:last-child {
//   margin-bottom: 0;
//   border-bottom-right-radius: 0;
//   border-bottom-left-radius: 0;
// }
#place-filter .input-group-text,
#zone-filter .input-group-text {
  padding: 0px 10px !important;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.5;
  // color: #b6b6b6;
  text-align: center;
  white-space: nowrap;
  // background-color: transparent;
  // border: none;
  // border-left: none;
  border-radius: 0 0.25rem 0.25rem 0;
  height: 100%;
}
.input-group-text-vehicles {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  font-weight: 700;
  line-height: 1.5;
  //color: #b6b6b6;
  color: #565e64;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: none;
  // border-left: none;
  border-radius: 0 0.25rem 0.25rem 0;
  height: 100%;
}
[v-cloak] > * {
  display: none;
}
[v-cloak]::before {
  content: 'loading…';
}

.vue-js-switch .v-switch-label.v-left {
  color: $white !important;
}
.vue-js-switch .v-switch-label.v-right {
  color: $white !important;
}
/* ---- grid ---- */

/* clear fix */

.grid:after {
  content: '';
  display: block;
  clear: both;
}

.grid-item {
  float: left;
  width: 300px;
}

.registration-plate {
  position: relative;
  min-width: 170px;
  min-height: 45px;
  text-align: center;
  padding: 10px 30px;
  background-color: black;
  border-radius: 2px;
  color: white;
  font-weight: 900;
  font-size: 1rem;
  word-wrap: normal;
  &:before {
    position: absolute;
    left: 2px;
    top: 2px;
    content: '';
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border: 2px solid white;
    border-radius: 2px;
  }
  .rivet {
    display: block;
    position: absolute;
    content: '';
    width: 2px;
    height: 2px;
    background-color: white;
    border-radius: 50%;
    &.rivet--top-left {
      left: 8px;
      top: 8px;
    }
    &.rivet--top-right {
      right: 8px;
      top: 8px;
    }
    &.rivet--bottom-left {
      left: 8px;
      bottom: 8px;
    }
    &.rivet--bottom-right {
      right: 8px;
      bottom: 8px;
    }
  }
  &.registration-plate--rent {
    background-color: #3079ca;
    color: white;
  }
  &.registration-plate--ministry {
    background-color: white;
    color: #aa0000;
    &:before {
      border: 2px solid #aa0000;
    }
    .rivet {
      background-color: #aa0000;
    }
  }
}
.vehicle-item {
  font-size: 0.9rem;
  color: #495057;
}
.minh {
  min-height: 50px !important;
}
.minh-1 {
  min-height: 100px !important;
}
.minh-2 {
  min-height: 200px !important;
}
.minh-3 {
  min-height: 300px !important;
}
.minh-4 {
  min-height: 400px !important;
}

.maxh {
  max-height: 50px !important;
}
.maxh-1 {
  max-height: 100px !important;
}
.maxh-2 {
  max-height: 200px !important;
}
.maxh-3 {
  max-height: 300px !important;
}
.maxh-4 {
  max-height: 400px !important;
}

.tags_selected-color {
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 100%;
}
.tag-list {
  display: flex;
  align-items: center;
  justify-content: left;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;
  .tag-list__item {
    margin: 4px 8px 8px 0;
    .tag-list__btn {
      position: relative;
      display: block;
      width: 26px;
      height: 26px;
      border-radius: 100%;
      opacity: 1;
      transition: all 0.3sec ease-in;
      &:hover,
      &.tag-list__btn--selected {
        opacity: 1;
        &::after {
          content: '';
          position: absolute;
          display: block;
          top: -4px;
          left: -4px;
          width: 34px;
          height: 34px;
          border-radius: 100%;
          border: 1px solid #000;
          opacity: 1;
        }
      }
    }
  }
}

.avatar {
  width: 150px;
  height: 150px;
}

span#note {
  display: block;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.animate-btn {
  position: relative;
  -webkit-transition-duration: 0.4s; /* Safari */
  transition-duration: 0.4s;
  overflow: hidden;
}

.animate-btn:after {
  content: '';
  background: grey;
  display: block;
  position: absolute;
  padding-top: 300%;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.animate-btn:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;
}

#alarm-log .vdp-datepicker__calendar,
.trips-list .vdp-datepicker__calendar,
.stops-list .vdp-datepicker__calendar {
  width: auto !important;
  // right: -60px !important;
  // @include media-breakpoint-up(lg) {
  //   right: 0px !important;
  // }
}

#breadcrumb-administration .dropdown-toggle::after {
  display: none !important;
}
#headerApp {
  height: 58px;
  border-bottom: 1px solid #ced4da !important;
}

#headerApp .dropdown-toggle::after {
  display: none !important;
}
.stopsTrips-nav {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}
.stopsTrips-nav .active {
  border-bottom: 3px solid $primary-color;
  font-weight: bold;
}

#tree-list .dropdown-toggle::after {
  display: none !important;
}
.breadcrumb-fleet {
  font-size: 14px !important;
}
// number of cards per line for each breakpoint
$cards-per-line1: (
  xs: 1,
  sm: 1,
  md: 2,
  lg: 3,
  xl: 3,
);
$cards-per-line2: (
  xs: 1,
  sm: 1,
  md: 1,
  lg: 2,
  xl: 2,
);

@each $name, $breakpoint in $grid-breakpoints {
  @media (min-width: $breakpoint) {
    .card-deck .card {
      flex: 0 0
        calc(#{100 / map-get($cards-per-line1, $name)}% - #{$grid-gutter-width});
      margin-bottom: 1rem;
    }
    .card-deck--large .card {
      flex: 0 0
        calc(#{100 / map-get($cards-per-line2, $name)}% - #{$grid-gutter-width});
    }
  }
}
.toggle.collapsed {
  transform: rotate(0deg);
  transition: transform 0.5s linear;
}
.toggle {
  transform: rotate(180deg);
  transition: transform 0.5s linear;
}
.dropdown-toggle::after {
  display: none !important;
}
.dropdown-toggle::before {
  display: none !important;
}

.cropped {
  width: 150px; /* width of container */
  height: 150px; /* height of container */
  overflow: hidden;
}

.cropped img {
  margin: -10px 0px 0px -180px;
}

#filter-dropdown {
  width: 300px !important;
  @include media-breakpoint-up(sm) {
    width: 457px !important;
  }
}
#filterPLaces-dropdown {
  width: 400px !important;
  @include media-breakpoint-up(md) {
    top: -50px !important;
    width: 523px !important;
  }
}

#filter-dropdownS {
  width: 300px !important;
  @include media-breakpoint-up(md) {
    top: -50px !important;
    width: 433px !important;
  }
}
#filterTrip-dropdown {
  width: 408px !important;
  @include media-breakpoint-up(md) {
    width: 365px !important;
    top: -42px !important;
    @include media-breakpoint-up(lg) {
      width: 485px !important;
    }
  }
}
#filterStop-dropdown {
  width: 408px !important;
  @include media-breakpoint-up(md) {
    width: 365px !important;
    top: -42px !important;
    @include media-breakpoint-up(lg) {
      width: 465px !important;
    }
  }
}
#filter-dropdown-trashed {
  width: 300px !important;
  @include media-breakpoint-up(sm) {
    width: 350px !important;
  }
}
#placesDriver {
  margin-left: 15px !important;
}
.switch {
  font-size: 0.9rem !important;
  &:hover,
  &.active,
  &:focus {
    background-color: #d3d0dc;
    color: #5f465c;
  }
}
#btnDropdownMenu {
  border: 1px solid #ced4da;
  border-left: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

#drivers-search {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

#drivers-search:focus {
  border-color: #ced4da !important;
}

.dirver-name {
  font-size: 0.9rem !important;
  font-weight: 600;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0.2rem !important;
  // top: 1px !important;
}
// .custom-control-label::after {
//   top: 1px !important;
// }

ul#admin-nav {
  width: 150px !important;
}

// .modal-borderless {
//   border-radius: 1.3rem !important;
// }

.modal-borderless .modal-header,
.modal-borderless .modal-footer,
.modal-borderless .modal-content {
  border: none !important;
}

.card-borderless .card-header,
.card-borderless .card-footer {
  border: none !important;
  background: transparent;
}

.StopDetails {
  font-size: 16px;
  font-family: openSans;
}
.stat-item {
  font-size: 0.875rem;
  font-weight: 300;
  &__title {
    font-weight: 700 !important;
  }
  &__key {
    font-size: 1.3rem;
    font-weight: 600;
  }
  &__unit {
    font-size: 0.875rem;
    font-weight: 300;
  }
  &__date {
    font-weight: 600;
  }
  &__time {
    font-weight: 600;
  }
}
.sep {
  border: none;
  @include media-breakpoint-up(sm) {
    height: 80%;
    vertical-align: center;
    border-left: 1px solid $secondary-color-lighter;
  }
}
.sep-right {
  border: none;
  @include media-breakpoint-up(sm) {
    border-right: 1px solid #dee2e6;
  }
}
.sep-dash-right {
  border: none;
  @include media-breakpoint-up(sm) {
    border-right: 1px dashed #dee2e6;
  }
}
.action-box {
  display: block;
  @include media-breakpoint-up(sm) {
    display: flex;
    justify-content: space-between;
  }
  &__search {
    margin-top: 0px;
    @include media-breakpoint-up(sm) {
      margin-top: 0px;
    }
  }
  &__moreSearch {
    padding-left: 5 !important;
    //padding-top: 0 !important;
    //padding-bottom: 0 !important;
    @include media-breakpoint-up(sm) {
      //padding-left: 10px !important;
    }
  }
}

.mission-detail {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  background: #fff;
  position: absolute;
  top: 58px;
  left: 25%;
  height: calc(100vh - 94px);
  border-radius: 0;
  z-index: 9;
  width: 400px;
  box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
}
.realtime-detail {
  transition: all 0.5s;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  background: #fff; // #f5f5f5;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 370px;
  // $width-realtime-detail: var(width);
  // box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.2);
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  // border-radius: 5px;
  a.active {
    background: rgba(0, 0, 0, 0.05);
    color: #5f3056 !important;
  }
}

.truncate {
  width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.taskline {
  position: relative;
  border-left: 5px solid #5da8df;
  width: 1px;
}

.timeline {
  position: relative;
  left: 17px;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
.timeline-card {
  border-left: 2px solid #5f3056;
  background: #fff;
}
.timeline-card > div {
  background: #5f30560a;
}

div.taskline:last-child {
  display: none;
}

.point {
  position: relative;
  left: -14px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 2px solid #5f3056;
  background: #fff;
}
.task-start {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  // background: #00242b;
  background: #ee5750; // second option
  border: 2px solid #f5f5f5;
  margin-top: 12px;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  // outline: 2px solid #5f465a;
}
.task-start::after {
  content: '';
  position: absolute;
  top: 0;
  transform: translateX(-123%);
  width: 4px;
  min-height: 35px;
  z-index: -1;
  // background: #5f465a;
  background: #5da8df; // second option
}
.task {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  // background: #5f465a;
  background: #ee5750; // second option
  border: 2px solid #f5f5f5;
  margin-top: 12px;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  // outline: 2px solid #5f465a;
}
.task::after {
  content: '';
  position: absolute;
  top: -16px;
  transform: translateX(-123%);
  width: 4px;
  min-height: 50px;
  z-index: -1;
  // background: #5f465a;
  background: #5da8df; // second option
}
.task-end {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  // background: #00242b;
  background: #ee5750; // second option
  border: 2px solid #f5f5f5;
  margin-top: 12px;
  font-size: 10px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  // outline: 2px solid #5f465a;
}
.task-end::after {
  content: '';
  position: absolute;
  bottom: 0;
  transform: translateX(-123%);
  width: 4px;
  min-height: 35px;
  z-index: -1;
  // background: #5f465a;
  background: #5da8df; // second option
}

.taskline-row {
  border-radius: 3px;
  cursor: pointer;
  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }
}

.mini-card {
  border: 2px solid #e7e3e7 !important;
}
.badSignal-bg {
  border: 2px solid red;
  // background-color: rgba(192, 57, 43, 0.1);
  color: red;
  border-radius: 50%;
  padding: 4px 5px;
}
.untracked-bg {
  border: 2px solid #f39c12;
  // background-color: #ffc10738;
  color: #f39c12;
  border-radius: 50%;
  padding: 3px 6px;
}
.moving-bg {
  border: 2px solid #28a745;
  // background-color: #28a7452b;
  color: #28a745;
  border-radius: 50%;
  padding: 4px 5px;
}
.parked-bg {
  border: 2px solid #2b92ed;
  // background-color: #28a7452b;
  color: #2b92ed;
  border-radius: 50%;
  padding: 4px 5px;
}
.stopping-bg {
  border: 2px solid red;
  // background-color: #dc354521;
  color: red;
  border-radius: 50%;
  padding: 3px;
  border: 2px solid;
  background-color: white !important;
}

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(-60vw);
  // opacity: 0;
}
.slide-fade-enter {
  transform: translateX(-60vw);
  opacity: 0;
}

.border-primary {
  border: 1px solid #e1e5ee !important;
}
.badge-transparent {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 4px 7px;
  border-radius: 10%;
}
.mileage-edit {
  position: absolute;
  right: 23px;
  color: #9c62b6;
}
.mileage-badge {
  background-color: #9b59b61a;
  padding: 5px 8px;
  color: #9b59b6;
  border-radius: 10%;
}
.mileage-icon {
  border: none;
  padding: 6px 9px;
  color: #9b59b6;
  border-radius: 50%;
}
.mileage-icon-v2 {
  border: none;
  padding: 3px 6px;
  color: #9b59b6;
  border-radius: 50%;
  background-color: #ffffff;
}
.fuel-badge {
  background-color: #f39c121a;
  padding: 5px 9px;
  color: #f39c12;
  border-radius: 10%;
}
.consumption-badge {
  background-color: #d354001a;
  padding: 5px 12px;
  color: #d35400;
  border-radius: 50%;
}

.table-item {
  font-weight: 400;
  font-size: 1rem;
  border: none !important;
}
.clock-badge {
  background-color: #3498db1a;
  padding: 5px 9px;
  color: #3498db;
  border-radius: 10%;
}

.rounded-xl {
  border-radius: 20px;
}

.progress {
  width: 90%;
  height: 45px;
  background: none;
  position: relative;
  border: 3px solid #e1e5ee;
}

.speed-success {
  color: #28a745;
}

.speed-danger {
  color: #ff6b6b;
  animation: colorblink 2s infinite;
}

@keyframes colorblink {
  0% {
  }
  30% {
    color: white;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.3s ease;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(-100px);
  opacity: 0;
}

.fade-up-enter-active,
.fade-up-leave-active {
  transition: all 0.3s ease;
}
.fade-up-enter,
.fade-up-leave-to {
  transform: translateY(100px);
  opacity: 0;
}
.progress .progress-value {
  position: absolute;
  top: 19%;
  left: 12%;
}

div.arrow-line {
  height: 60px;
  width: 5px;
  background-color: #5f465a;
}

div.trips-list {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  height: calc(100vh - 62px);
}
div.trips-chart {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  height: calc(50vh - 88px);
}
div.realtime-trips-chart {
  background: #fff;
  transition: all 0.5s;
  z-index: 9;
  // margin: 6px;
  // border-radius: 5px;
  width: calc(100% - 370px);
  position: absolute;
  right: 0;
  bottom: 0;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  height: 230px !important;
}
div.speed-stat-card {
  z-index: 9;
  margin: 0;
  width: 100%;
  position: absolute;
  bottom: 0;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  // box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.2);
  scrollbar-width: thin;
  max-height: 230px;
}

div.stops-list {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  height: calc(100vh - 91px);
}

div.realtime-list {
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  height: calc(100vh - 88px);
  z-index: 9;
}
div.missions-list {
  overflow-y: scroll;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  height: calc(100vh - 88px);
}

div.detail-card {
  height: calc(100vh - 92px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
}

div.places-list {
  height: calc(100vh - 94px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  @include media-breakpoint-up(lg) {
    max-height: calc(100vh - 95px);
  }
}
div.zones-list {
  height: calc(100vh - 134px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
}
div.expenses-list {
  height: calc(100vh - 74px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
}

div.expenses-list .table th:hover,
.active-th {
  .th-icon {
    color: #000 !important;
  }
}

div.calendar-card {
  height: calc(100vh - 74px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
}

div.alerts-card {
  height: calc(100vh - 220px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
}
div.alertLogs-card {
  height: calc(100vh - 220px) !important;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
}

div::-webkit-scrollbar,
div::-webkit-scrollbar:horizontal {
  width: 8px;
}

div::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
div::-webkit-scrollbar-track-piece {
  background: rgba(0, 0, 0, 0.05);
}

ul::-webkit-scrollbar {
  width: 10px;
}

ul::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.2);
}
.organization-card {
  width: 99% !important;
  height: calc(100vh - 75px);
}
.organization-nav-bar {
  height: calc(100vh - 157px) !important;
  border-right: 1px solid $gray-300 !important;
  // padding-left: 0 !important;
}
.organization-grid {
  max-height: calc(100vh - 154px) !important;
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  @include media-breakpoint-up(lg) {
    //max-height:none !important;
    overflow-y: none !important;
  }
}
.organization-memebership {
  max-height: calc(100vh - 125px) !important;
  overflow-y: auto;
  scrollbar-color: rgba(0, 0, 0, 0.2) none;
  scrollbar-width: thin;
  @include media-breakpoint-up(lg) {
    //max-height:none !important;
    overflow-y: none !important;
  }
}
.organization-information {
  max-height: calc(100vh - 154px) !important;
  //overflow-y: scroll;
}

.org-owner-transfer {
  max-height: calc(100vh - 327px) !important;
}

.maxSpeedLog-list {
  max-height: calc(100vh - 137px) !important;
  // overflow-y: scroll;
  // overflow-x: overlay;
}
.maxSpeedSettings-list {
  max-height: calc(100vh - 183px) !important;
  // overflow-y: scroll;
  // overflow-x: overlay;
}
.driver-row {
  border-bottom: 1px solid $gray-300 !important;
}
.driver-avatar {
  margin-bottom: 15px;
}
.primary-badge {
  background: rgba(95, 70, 92, 0.15);
  font-weight: 600;
  color: #5f465c;
}
.warning-badge {
  background: rgba(253, 126, 20, 0.15);
  color: #fd7e14;
  font-weight: 600;
}
.danger-badge {
  background: rgba(220, 53, 69, 0.15);
  color: #dc3545;
  font-weight: 600;
}
.info-badge {
  background: rgba(23, 162, 184, 0.15);
  color: #17a2b8;
  font-weight: 600;
}
.success-badge {
  background: rgba(40, 167, 69, 0.15);
  color: #28a745;
  font-weight: 600;
}

.border-left {
  border-bottom: 1px solid #bdc3c7 !important;
}

.primary-card {
  background: rgb(95, 70, 89);
  border: none;
  color: white;
}
.row-tag {
  ul {
    list-style: none;
  }
}
.place-map {
  border-left: 1px solid #ced4da;
}
.active-primary {
  background: #5f4659 !important;
  color: white !important;
}
.active-row {
  background: #f5f5f5 !important;
  border-radius: 5px;
}
.checked-row {
  background: rgba(95, 48, 86, 0.1) !important;

  border-radius: 10px;
}
.mission-row {
  position: relative;
  border-radius: 10px;
  cursor: pointer;
}
.event-row {
  position: relative;
  border-radius: 10px;
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
}
.realtime-row {
  border-radius: 10px;
  cursor: pointer;
}

.small-circle {
  border-width: 2px !important;
  border-radius: 50%;
  padding: 5px;
  width: 47px;
  height: 47px;
}

.stop-progress-bar .progress-bar {
  margin-right: 2px;
  padding: 0 4px;
}
.stop-progress-bar .progress-bar:last-child {
  margin-right: 0;
}
.alarme-filter {
  display: flex;
  justify-content: space-between;
  @include media-breakpoint-up(md) {
    display: contents;
  }
}
.date-pick {
  max-width: 21% !important;
}
#calendarEvent-form {
  position: -webkit-sticky;
  position: sticky;
  top: 2rem;
  padding: 1rem;
  line-height: 1.5;
  box-decoration-break: clone;
  z-index: 1;
  transition: all 300ms;
  will-change: color, background-color;
  // input, button, textarea, .vue-treeselect__control{
  //   border-radius: 0!important;
  // }
  #rest-btn {
    border: 2px solid #28a745;
    color: #28a745;
    &.active {
      background-color: #28a745;
      color: #fff;
    }
  }
  #driver-btn {
    border: 2px solid #f39c12;
    color: #f39c12;
    &.active {
      background-color: #f39c12 !important;
      color: #fff !important;
    }
  }
  #trail-btn {
    border: 2px solid #3498db;
    color: #3498db;
    &.active {
      background-color: #3498db;
      color: #fff;
    }
  }
  #handle-btn {
    border: 2px solid #9b59b6;
    color: #9b59b6;
    &.active {
      background-color: #9b59b6;
      color: #fff;
    }
  }
  .nav-link.active {
    border-bottom: 2px solid #5f465c !important;
  }
}
.status {
  width: 16px;
}

fieldset.task-border {
  border: 1px solid #ced4da;
  padding: 0 1.4em 1.4em 1.4em !important;
  // margin: 0 0 1.5em 0 !important;
}

legend.task-border {
  font-size: 1.2em !important;
  font-weight: bold !important;
  text-align: left !important;
  width: auto;
  padding: 0 10px;
  border-bottom: none;
}

#edit-mileage-icon {
  vertical-align: text-top;
}

.fuel-tank-input {
  padding: 0.49rem 0.75rem;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-top-left-radius: 0.2rem !important;
  border-bottom-left-radius: 0.2rem !important;
}
.active-nav-item {
  border-radius: 0 !important;
  border-bottom: 2px solid $primary-color !important;
  background: transparent !important;
  color: $primary-color !important;
}

.bg-dark-transparent {
  background: rgba(0, 0, 0, 0.05);
}

.realtime-detail-card {
  border: 0;
  padding: 10px 10px 0px 10px;
  height: 100%;
  &__icon {
    padding: 10px;
    border-radius: 50%;
    background: rgba(0, 0, 0, 0.1);
    color: rgba(255, 255, 255, 0.9);
  }
  &__label {
    margin-top: 5px;
    color: #000;
  }
}

.task-address {
  white-space: nowrap;
  width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.realtime-detail-active,
.realtime-detail-active:hover {
  background: #5f3056;
  border-radius: 0;
  color: #fff;
}

.card-columns {
  @include media-breakpoint-only(sm) {
    column-count: 4;
  }
  @include media-breakpoint-only(xl) {
    column-count: 5;
  }
}

.custom-switch .custom-control-label::after {
  // top: calc(0.15625rem + 3px) !important;
}

.cursor-pointer {
  cursor: pointer;
}
.cursor-default {
  cursor: default;
}

.status-btn {
  padding: 0 5px 2px 5px;
  color: #5f3056 !important;
}
.active-status {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.breadcrump-seperator:after {
  content: ' \203A';
  color: #90959e;
  margin: 0 4px;
}

.horizontal-admin-nav {
  white-space: nowrap !important;
  overflow-x: auto !important;
  background: #fff !important;
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.horizontal-admin-nav li {
  display: inline-block !important;
  float: none !important;
}

.alarm-icon {
  position: absolute;
  top: 0px;
  right: 5px;
}

.success-checkbox::before {
  background-color: rgba(40, 167, 69, 0.15) !important;
  border: none;
}
.success-checkbox::after {
  background-color: #28a745 !important;
  color: #28a745 !important;
}
.error-checkbox::before {
  background-color: rgba(220, 53, 69, 0.15) !important;
  border: none;
}
.error-checkbox::after {
  background-color: #dc3545 !important;
}

.tooltip-inner {
  white-space: pre-wrap;
}

#pie-chart {
  height: 220px !important;
  width: auto;
}

.nav-tabs a.nav-link {
  color: #495057 !important;
}
.nav-tabs a.nav-link:hover {
  border: none !important;
}
.nav-tabs .nav-link.active {
  border: none;
  background-color: transparent;
  color: #5f3056 !important;
  border-bottom: 2px solid #5f3056 !important;
}
