.btn {
  font-weight: normal;
  border: 1px solid transparent;
  padding: 0.5rem 0.75rem;
  font-size: 1rem;
  line-height: 1.25;
  border-radius: 0.25rem;
  -webkit-transition: all 0.15s ease-in-out;
  transition: all 0.15s ease-in-out;
  @include clickable;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none;
}

.btn:active,
.btn.active {
  background-image: none;
}

.btn-primary {
  color: #fff;
  background-color: $primary-color;
  border-color: $primary-color;
}

.btn-primary:hover {
  color: #fff;
  background-color: $primary-color-lighter;
  border-color: $primary-color-lighter;
}

.btn-primary:focus,
.btn-primary.focus {
  box-shadow: none;
}

.btn-primary.disabled,
.btn-primary:disabled {
  background-color: $primary-color-lighter;
  border-color: $primary-color-lighter;
}

.btn-primary:active,
.btn-primary.active,
.show > .btn-primary.dropdown-toggle {
  background-image: none;
  background-color: $primary-color-lighter;
  border-color: $primary-color-lighter;
}

.btn-secondary {
  color: #fff;
  background-color: $secondary-color;
  border-color: $secondary-color;
}

.btn-secondary:hover {
  color: #fff;
  background-color: $secondary-color-lighter;
  border-color: $secondary-color-lighter;
}

.btn-secondary:focus,
.btn-secondary.focus {
  box-shadow: none;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-secondary:active,
.btn-secondary.active,
.show > .btn-secondary.dropdown-toggle {
  background-image: none;
  background-color: $secondary-color-lighter;
  border-color: $secondary-color-lighter;
}

.btn-accent {
  color: #fff;
  background-color: $accent-color;
  border-color: $accent-color;
}

.btn-accent:hover {
  color: $secondary-color-darker;
  background-color: $accent-color-lighter;
  border-color: $accent-color-lighter;
}

.btn-accent:focus,
.btn-accent.focus {
  box-shadow: none;
}

.btn-accent.disabled,
.btn-accent:disabled {
  background-color: #868e96;
  border-color: #868e96;
}

.btn-accent:active,
.btn-accent.active,
.show > .btn-accent.dropdown-toggle {
  background-image: none;
  background-color: $accent-color-lighter;
  border-color: $accent-color-lighter;
}

.btn-success {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:hover {
  color: #fff;
  background-color: #218838;
  border-color: #1e7e34;
}

.btn-success:focus,
.btn-success.focus {
  box-shadow: none;
}

.btn-success.disabled,
.btn-success:disabled {
  background-color: #28a745;
  border-color: #28a745;
}

.btn-success:active,
.btn-success.active,
.show > .btn-success.dropdown-toggle {
  background-color: #218838;
  background-image: none;
  border-color: #1e7e34;
}

.btn-info {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:hover {
  color: #fff;
  background-color: #138496;
  border-color: #117a8b;
}

.btn-info:focus,
.btn-info.focus {
  box-shadow: none;
}

.btn-info.disabled,
.btn-info:disabled {
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-info:active,
.btn-info.active,
.show > .btn-info.dropdown-toggle {
  background-color: #138496;
  background-image: none;
  border-color: #117a8b;
}

.btn-warning {
  color: #111;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:hover {
  color: #111;
  background-color: #e0a800;
  border-color: #d39e00;
}

.btn-warning:focus,
.btn-warning.focus {
  box-shadow: none;
}

.btn-warning.disabled,
.btn-warning:disabled {
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-warning:active,
.btn-warning.active,
.show > .btn-warning.dropdown-toggle {
  background-color: #e0a800;
  background-image: none;
  border-color: #d39e00;
}

.btn-danger {
  color: #fff;
  background-color: $red;
  border-color: $red;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c82333;
  border-color: #bd2130;
}

.btn-danger:focus,
.btn-danger.focus {
  box-shadow: none;
}

.btn-danger.disabled,
.btn-danger:disabled {
  background-color: rgba(220, 53, 69, 0.5);
  border-color: rgba(220, 53, 69, 0.5);
}

.btn-danger:active,
.btn-danger.active,
.show > .btn-danger.dropdown-toggle {
  background-color: #c82333;
  background-image: none;
  border-color: #bd2130;
}

.btn-light {
  color: #111;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:hover {
  color: #111;
  background-color: #e2e6ea;
  border-color: #dae0e5;
}

.btn-light:focus,
.btn-light.focus {
  box-shadow: none;
}

.btn-light.disabled,
.btn-light:disabled {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-light:active,
.btn-light.active,
.show > .btn-light.dropdown-toggle {
  background-color: #e2e6ea;
  background-image: none;
  border-color: #dae0e5;
}

.btn-dark {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:hover {
  color: #fff;
  background-color: #23272b;
  border-color: #1d2124;
}

.btn-dark:focus,
.btn-dark.focus {
  box-shadow: none;
}

.btn-dark.disabled,
.btn-dark:disabled {
  background-color: #343a40;
  border-color: #343a40;
}

.btn-dark:active,
.btn-dark.active,
.show > .btn-dark.dropdown-toggle {
  background-color: #23272b;
  background-image: none;
  border-color: #1d2124;
}

.btn-outline-primary {
  color: $primary-color;
  background-color: transparent;
  background-image: none;
  border-color: $primary-color;
}

.btn-outline-primary:hover {
  color: #fff;
  background-color: $primary-color-light;
  border-color: $primary-color-light;
}

.btn-outline-primary:focus,
.btn-outline-primary.focus {
  box-shadow: none;
}

.btn-outline-primary.disabled,
.btn-outline-primary:disabled {
  color: $primary-color-lighter;
  background-color: transparent;
}

.btn-outline-primary:active,
.btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #fff;
  background-color: $primary-color-light;
  border-color: $primary-color-light;
}

.btn-outline-secondary {
  color: #868e96;
  background-color: transparent;
  background-image: none;
  border-color: #868e96;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-secondary:focus,
.btn-outline-secondary.focus {
  box-shadow: none;
}

.btn-outline-secondary.disabled,
.btn-outline-secondary:disabled {
  color: #868e96;
  background-color: transparent;
}

.btn-outline-secondary:active,
.btn-outline-secondary.active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #868e96;
  border-color: #868e96;
}

.btn-outline-success {
  color: #28a745;
  background-color: transparent;
  background-image: none;
  border-color: #28a745;
  //font-size: 0.8rem !important;
}

.btn-outline-success:hover {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-success:focus,
.btn-outline-success.focus {
  box-shadow: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
  color: #28a745;
  background-color: transparent;
}

.btn-outline-success:active,
.btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle {
  color: #fff;
  background-color: #28a745;
  border-color: #28a745;
}

.btn-outline-info {
  color: #17a2b8;
  background-color: transparent;
  background-image: none;
  border-color: #17a2b8;
}

.btn-outline-info:hover {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-info:focus,
.btn-outline-info.focus {
  box-shadow: none;
}

.btn-outline-info.disabled,
.btn-outline-info:disabled {
  color: #17a2b8;
  background-color: transparent;
}

.btn-outline-info:active,
.btn-outline-info.active,
.show > .btn-outline-info.dropdown-toggle {
  color: #fff;
  background-color: #17a2b8;
  border-color: #17a2b8;
}

.btn-outline-warning {
  color: #ffc107;
  background-color: transparent;
  background-image: none;
  border-color: #ffc107;
}

.btn-outline-warning:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-warning:focus,
.btn-outline-warning.focus {
  box-shadow: none;
}

.btn-outline-warning.disabled,
.btn-outline-warning:disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-warning:active,
.btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-outline-danger {
  color: $red;
  background-color: transparent;
  background-image: none;
  border-color: $red;
}

.btn-outline-danger:hover {
  color: #fff;
  background-color: $red;
  border-color: $red;
}

.btn-outline-danger:focus,
.btn-outline-danger.focus {
  box-shadow: none;
}

.btn-outline-danger.disabled,
.btn-outline-danger:disabled {
  color: $red;
  background-color: transparent;
}

.btn-outline-danger:active,
.btn-outline-danger.active,
.show > .btn-outline-danger.dropdown-toggle {
  color: #fff;
  background-color: $red;
  border-color: $red;
}

.btn-outline-light {
  color: #f8f9fa;
  background-color: transparent;
  background-image: none;
  border-color: #f8f9fa;
}

.btn-outline-light:hover {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-light:focus,
.btn-outline-light.focus {
  box-shadow: none;
}

.btn-outline-light.disabled,
.btn-outline-light:disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-light:active,
.btn-outline-light.active,
.show > .btn-outline-light.dropdown-toggle {
  color: #fff;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-outline-dark {
  color: #343a40;
  background-color: transparent;
  background-image: none;
  border-color: #343a40;
}

.btn-outline-dark:hover {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-outline-dark:focus,
.btn-outline-dark.focus {
  box-shadow: none;
}

.btn-outline-dark.disabled,
.btn-outline-dark:disabled {
  color: #343a40;
  background-color: transparent;
}

.btn-outline-dark:active,
.btn-outline-dark.active,
.show > .btn-outline-dark.dropdown-toggle {
  color: #fff;
  background-color: #343a40;
  border-color: #343a40;
}

.btn-lg {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  border-radius: 0.3rem;
}

.btn-sm {
  padding: 0.1rem 0.5rem;
  font-size: 0.775rem;
  line-height: 1.2rem;
  border-radius: 0.2rem;
}

.primary-btn {
  background: rgba(95, 70, 92, 0.15);
  border: none;
  color: #5f465c;
}
.success-btn {
  background: rgba(40, 167, 69, 0.15);
  border: none;
  color: #28a745;
}
.info-btn {
  background: rgba(23, 162, 184, 0.15);
  border: none;
  color: #17a2b8;
}
.danger-btn {
  background: rgba(220, 53, 69, 0.15);
  border: none;
  color: #dc3545;
}
.warning-btn {
  background: rgba(253, 126, 20, 0.15);
  border: none;
  color: #fd7e14;
}
.text-group-search{
  &:active{
    animation: pulse 3s 3s ease-in infinite;
  }
 &__icon{
    &:hover {
      color: $black;
    }
  }

  
}
