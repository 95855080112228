/*====== Checklist Box ======*/

.checklist label {
  font-size: 0.8em;
  color: #999;
  line-height: 1.8em;
}

.checklist .item {
  padding: 20px;
}

.checklist .item:nth-of-type(even) {
  background: #fafafa;
}

/*====== Trending Articles Box ======*/

.articles a {
  text-decoration: none !important;
  display: block;
  margin-bottom: 0;
  color: #555;
}

.articles .badge {
  font-size: 0.7em;
  padding: 5px 10px;
  line-height: 1;
  margin-left: 10px;
}

.articles .item {
  padding: 20px;
}

.articles .item:nth-of-type(even) {
  background: #fafafa;
}

.articles .item .image {
  min-width: 50px;
  max-width: 50px;
  height: 50px;
  margin-right: 15px;
}

.articles .item img {
  padding: 3px;
  border: 1px solid #28a745;
}

.articles .item h3 {
  color: #555;
  font-weight: 400;
  margin-bottom: 0;
}

.articles .item small {
  color: #aaa;
  font-size: 0.75em;
}
