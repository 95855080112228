footer.main-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 36px;
  background-color: #fafafa;
  border-top: 1px solid #ededed;
  color: #686d71;
  z-index: 1000;
  .main-footer__copyright {
    position: relative;
    width: 100%;
    margin: 10px 0;
    text-align: center;
  }
  .main-footer__menu {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    .main-footer__menu-item {
      position: relative;
      padding-right: 15px;
      &:after {
        position: absolute;
        top: 0;
        right: 5px;
        content: '-';
      }
      &:last-of-type {
        &:after {
          display: none;
        }
      }
      .main-footer__menu-link {
        color: $primary-color;
        font-size: 0.75rem;
        &:hover {
          color: $accent-color;
        }
      }
    }
  }
}

footer.main-footer a {
  color: inherit;
}

footer.main-footer p {
  margin: 0;
  font-size: 0.8em;
}

/* FOOTER MEDIAQUERIES  --------------------------------- */

@media (max-width: 575px) {
  footer.main-footer div[class*='col'] {
    text-align: center !important;
  }
}
