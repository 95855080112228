@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700&display=swap');

$icomoon-font-path: '../fonts' !default;

$atseeicon-fuel-station: '\e900';
$atseeicon-car-wash: '\e901';
$atseeicon-parking: '\e902';
$atseeicon-oil-change: '\e903';
$atseeicon-car-repair: '\e904';
$atseeicon-toll-road: '\e905';
$atseeicon-car-wheel: '\e906';
$atseeicon-car-insurance: '\e907';
$atseeicon-car-repair-check: '\e908';

@font-face {
  font-family: 'atseeone';
  src: url('#{$icomoon-font-path}/atseeone.eot');
  src: url('#{$icomoon-font-path}/atseeone.eot#iefix')
      format('embedded-opentype'),
    url('#{$icomoon-font-path}/atseeone.ttf') format('truetype'),
    url('#{$icomoon-font-path}/atseeone.woff') format('woff'),
    url('#{$icomoon-font-path}/atseeone.svg#atseeone') format('svg');
  font-weight: normal;
  font-style: normal;
}

i[class^='atseeicon-'] {
  font-family: 'atseeone' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.atseeicon-fuel-station {
  &:before {
    content: $atseeicon-fuel-station;
  }
}
.atseeicon-car-wash {
  &:before {
    content: $atseeicon-car-wash;
  }
}
.atseeicon-parking {
  &:before {
    content: $atseeicon-parking;
  }
}
.atseeicon-oil-change {
  &:before {
    content: $atseeicon-oil-change;
  }
}
.atseeicon-car-repair {
  &:before {
    content: $atseeicon-car-repair;
  }
}
.atseeicon-toll-road {
  &:before {
    content: $atseeicon-toll-road;
  }
}
.atseeicon-car-wheel {
  &:before {
    content: $atseeicon-car-wheel;
  }
}
.atseeicon-car-insurance {
  &:before {
    content: $atseeicon-car-insurance;
  }
}
.atseeicon-car-repair-check {
  &:before {
    content: $atseeicon-car-repair-check;
  }
}

@font-face {
  font-family: 'dashboard';
  src: url('../fonts/dashboard.eot');
  src: url('../fonts/dashboard.eot?#iefix') format('embedded-opentype'),
    url('../fonts/dashboard.woff') format('woff'),
    url('../fonts/dashboard.ttf') format('truetype'),
    url('../fonts/dashboard.svg#dashboard') format('svg');
  font-weight: normal;
  font-style: normal;
}

[data-icon]:before {
  font-family: 'dashboard', sans-serif !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^='icon-']:before,
[class*=' icon-']:before {
  font-family: 'dashboard', sans-serif !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-home:before {
  content: '\62';
}

.icon-form:before {
  content: '\61';
}

.icon-list:before {
  content: '\63';
}

.icon-presentation:before {
  content: '\65';
}

.icon-bill:before {
  content: '\66';
}

.icon-check:before {
  content: '\67';
}

.icon-list-1:before {
  content: '\68';
}

.icon-padnote:before {
  content: '\69';
}

.icon-pencil-case:before {
  content: '\6a';
}

.icon-user:before {
  content: '\6b';
}

.icon-bars:before {
  content: '\6c';
}

.icon-line-chart:before {
  content: '\70';
}

.icon-flask:before {
  content: '\6d';
}

.icon-grid:before {
  content: '\6e';
}

.icon-picture:before {
  content: '\6f';
}

.icon-website:before {
  content: '\71';
}

.icon-screen:before {
  content: '\72';
}

.icon-interface-windows:before {
  content: '\73';
}

.icon-clock:before {
  content: '\74';
}

.icon-rss-feed:before {
  content: '\75';
}

.icon-mail:before {
  content: '\64';
}

.icon-ios-email-outline:before {
  content: '\76';
}

.icon-paper-airplane:before {
  content: '\77';
}

.icon-ios-email:before {
  content: '\78';
}

.icon-page:before {
  content: '\41';
}

.icon-close:before {
  content: '\79';
}

.icon-search:before {
  content: '\7a';
}
