.dashboard-card {
  display: flex;
  align-items: center;
  min-width: 160px;
  padding: 15px;
  @include media-breakpoint-up(md) {
    min-width: 200px;
  }
  .dashboard-card__icon {
    width: 35px !important;
    height: 35px !important;
    line-height: 35px !important;
    text-align: center;
    min-width: 35px;
    max-width: 35px;
    border-radius: 50%;
    @include media-breakpoint-up(md) {
      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
  .dashboard-card__title {
    margin: 0 15px;
    font-size: 0.875rem;
    font-weight: 300;
    color: $gray-600;
    text-align: left;
    white-space: nowrap;
    @include media-breakpoint-up(md) {
      font-size: 0.875rem;
    }
  }
  .dashboard-card__progress {
    margin-top: 10px;
    height: 4px;
  }
  .dashboard-card__number {
    font-size: 1.1rem;
    font-weight: 300;
  }
  .dashboard-card__number strong {
    font-weight: 400;
  }
  .dashboard-card__number sup {
    font-size: 0.5em;
    top: -1em;
  }
  .dashboard-card__row {
    padding: 30px 15px;
    margin: 0;
  }
}
