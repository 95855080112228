nav.navbar {
  background: #fff;
  color: #76838f;
  //border-bottom: 1px solid #ced4da!important;
  position: relative;
  border-radius: 0;
  z-index: 10;
  padding-left: 0;
  padding-right: 0;
  font-size: 14px;
  //border-bottom: 2.2px solid #ced4da !important;
  @include media-breakpoint-up(sm) {
    font-size: 14px;
  }
  &.navbar--transparent {
    background: transparent !important;
    box-shadow: none;
  }
  .container-fluid {
    width: 100%;
  }
  a {
    color: inherit;
  }
  .badge {
    min-width: 16px;
    height: 16px;
    line-height: 13px;
    text-align: center;
    padding: 2px;
    @include media-breakpoint-up(sm) {
      min-width: 16px;
      height: 16px;
    }
  }

  @media (max-width: 575px) {
    nav.navbar {
      font-size: 14px;
    }
    nav.navbar .nav-item > a {
      font-size: 13px;
    }
    nav.navbar .dropdown-menu {
      right: auto;
      left: -50%;
      -webkit-transform: translateX(-50%);
      transform: translateX(-50%);
    }
  }

  .navbar-holder {
    width: 100%;
  }
  .navbar-brand {
    .brand-small {
      display: block;
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
    .brand-big {
      display: none;
      @include media-breakpoint-up(xl) {
        display: block;
      }
    }
  }
  .menu-btn {
    margin-right: 20px;
    font-size: 1.2em;
    transition: all 0.7s;
  }
}

nav.navbar .nav-item > a {
  position: relative;
}

nav.navbar .nav-item > a span {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 400;
  font-size: 0.7em;
}

nav.navbar .nav-item > a.logout i {
  margin-left: 10px;
}

nav.navbar .search-box {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  background: #fff;
  z-index: 12;
  border-radius: 0;
  display: none;
}

nav.navbar .search-box .dismiss {
  position: absolute;
  top: 50%;
  right: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
  color: #999;
}

nav.navbar .search-box form {
  height: 100%;
}

nav.navbar .search-box form input {
  height: 100%;
  border: none;
  padding: 20px;
}

nav.navbar .dropdown-menu {
  right: 0;
  min-width: 250px;
  left: auto;
  // margin-top: 15px;
  padding: 0;
  max-width: 400px;
  border-radius: 3px;
  border: 1px solid #dcdfe6;
  background-color: #fff; // #F2F6FC;
  -webkit-box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1),
    -2px 0 2px rgba(0, 0, 0, 0.1);
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1), -2px 0 2px rgba(0, 0, 0, 0.1);
}

nav.navbar .dropdown-menu a {
  background: #fff;
  padding: 10px 20px;
  font-size: 0.8em;
  color: #777;
  width: 100%;
}

nav.navbar .dropdown-menu a:hover {
  background: #f5f5f5;
}

nav.navbar .dropdown-menu a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  margin-right: 10px;
}

nav.navbar .dropdown-menu a small {
  margin-left: 40px;
}

nav.navbar .dropdown-menu span {
  position: static;
  font-size: 0.9em;
  color: #999;
}

nav.navbar .dropdown-menu strong {
  font-weight: 700;
}

nav.navbar .dropdown-menu .msg-profile {
  width: 45px;
  height: 45px;
  margin-right: 10px;
}

nav.navbar .dropdown-menu h3 {
  font-weight: 500;
}

.nav-menu {
  .nav-item > a {
    margin-right: 10px;
  }
}

.organizations-dropdown-container {
  display: flex;
  flex-direction: row;
  width: 500px;
  height: 334px;
}

.hamburger {
  font-size: 17px;
  vertical-align: middle;
  &:before {
    display: block;
    width: 1em;
    height: 0.1em;
    margin: 0;
    background: #757575;
    border-radius: 1px;
    transition: transform 0s ease-in-out;
  }
  &:after,
  &:before {
    content: '';
  }
}

.hamburger .hamburger-bar,
.hamburger:after,
.hamburger:before {
  display: block;
  width: 1em;
  height: 0.1em;
  margin: 0;
  background: #ffffff;
  border-radius: 1px;
}

.hamburger .hamburger-bar,
.hamburger:after,
.hamburger:before {
  display: block;
  width: 1em;
  height: 0.1em;
  margin: 0;
  background: #ffffff;
  border-radius: 1px;
}

.hamburger .hamburger-bar {
  margin: 0.2em 0;
}

#toggle-btn.active .hamburger-arrow-left:before {
  width: 0.6em;
  transform: translate3d(0.45em, 0.1em, 0) rotate(45deg);
}

#toggle-btn.active .hamburger-arrow-left:after {
  width: 0.6em;
  transform: translate3d(0.45em, -0.1em, 0) rotate(-45deg);
}

#toggle-btn.active .hamburger-arrow-left {
  transform: rotate(180deg);
}

#organization-selector {
  height: 36px;
  width: 250px;
  font-size: 12px;
  border: none;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
}

// #organization-selector:focus {
//   background: #fff !important;
//   color: #000 !important;
// }

#organization-selector::after,
#organization-selector::before {
  color: #fff;
}

#organization-selector option {
  background: #fff;
  color: #000;
}
