/*
* ==========================================================
*     SIDEBAR
* ==========================================================
*/

nav.side-navbar {
  background: #5f3056;
  min-height: 100vh;
  width: 210px;
  color: rgba(255, 255, 255, 0.5);
  box-shadow: none;
  z-index: 100;
  transition: 0;
}

nav.side-navbar.shrinked {
  width: 60px !important;
  text-align: center;
}
nav.side-navbar div.brand-big,
nav.side-navbar div.brand-small {
  display: none;
}
nav.side-navbar.shrinked div.brand-big,
nav.side-navbar div.brand-small {
  display: none !important;
}
nav.side-navbar.shrinked div.brand-small,
nav.side-navbar div.brand-big {
  display: block !important;
}

nav.side-navbar.shrinked span.menu-label {
  display: none;
}
nav.side-navbar span.menu-label {
  display: inline;
}
nav.side-navbar.shrinked .sidebar-header h3 {
  display: none;
}
nav.side-navbar .active .sidebar-header strong {
  display: block;
}
nav.side-navbar ul li a {
  text-align: left;
  color: inherit;
}

nav.side-navbar.shrinked ul li a {
  padding: 15px 10px;
  text-align: center;
  font-size: 1.1em;
}

/* Same dropdown links padding*/
nav.side-navbar.shrinked ul ul a {
  padding: 10px !important;
}

nav.side-navbar a[aria-expanded='true'] {
  background: $accent-color-lighter;
}

nav.side-navbar a[aria-expanded='true']::before {
  content: '\f107';
}

nav.side-navbar .sidebar-header {
  padding: 30px 15px;
}

nav.side-navbar .avatar {
  width: 50px;
  height: 50px;
}

nav.side-navbar .title {
  margin-left: 10px;
}

nav.side-navbar .title h1 {
  color: #333;
}

nav.side-navbar .title p {
  font-size: 0.9em;
  font-weight: 200;
  margin-bottom: 0;
  color: #aaa;
}

nav.side-navbar {
  .side-navbar__logo {
  }
  .side-navbar__heading-avatar {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
    border-radius: 100%;
    // border: 1px solid #DDD;
  }
  .side-navbar__heading {
    padding: 20px 0 20px 0;
    .side-navbar__heading-title {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      font-weight: 400;
      color: $gray-700;
      font-size: 1rem;
      @include media-breakpoint-up(lg) {
        flex-direction: column;
      }
      .side-navbar__heading-text {
        width: 100%;
      }
    }
    .side-navbar__heading-subtitle {
      font-weight: 300;
      color: $gray-500;
      font-size: 0.8rem;
    }
  }

  .heading + ul {
    padding-top: 0 !important;
  }

  ul {
    overflow-y: auto;
    height: calc(100vh - 152px);
    padding: 0;
    scrollbar-width: thin;
    // @include media-breakpoint-up(lg) {
    //   overflow-y: hidden;
    // }
    // &:hover{
    //   overflow-y: overlay;
    // }
    &:first-child {
      padding: 15px 0 0;
    }
    li {
      a {
        padding: 10px 15px;
        text-decoration: none;
        display: block;
        border-left: 2px solid transparent;
        &:hover {
          color: #fff;
        }
      }
      &.active a {
        margin: 0 !important;
        background-color: rgba(255, 255, 255, 0.3);
        color: #fff;
        // border-left: 3px solid #e1b45a;
        // &:hover {
        //   background: transparentize(#fff, 0.2);
        // }
      }
    }
    ul {
      padding: 0;
      a {
        padding-left: 50px;
        background: $accent-color-lighter;
      }
    }
  }
  &.text-left d-none d-sm-table-cell {
    @include media-breakpoint-down(lg) {
      min-width: 120px;
      max-width: 120px;
      text-align: center;
      .heading {
        padding: 0;
      }
      .sidebar-header .title {
        display: none;
      }
      .side-navbar__heading {
        .side-navbar__heading-title {
          flex-direction: column;
        }
      }
      a {
        padding: 15px 10px;
        border: none;
        font-size: 0.8em;
        color: #aaa;
        -webkit-transition: color 0.3s, background 0.3s;
        transition: color 0.3s, background 0.3s;
        i {
          margin-right: 0;
          margin-bottom: 5px;
          display: block;
          font-size: 1.6em;
          color: #333;
          -webkit-transition: color 0.3s;
          transition: color 0.3s;
        }
        &:hover {
          color: #fff;
          border: none;
          i {
            color: #fff;
          }
        }
      }
      a[data-toggle='collapse']::before {
        content: '\f107';
        -webkit-transform: translateX(50%);
        transform: translateX(50%);
        position: absolute;
        top: auto;
        right: 50%;
        bottom: 0;
        left: auto;
      }
      a[data-toggle='collapse'][aria-expanded='true']::before {
        content: '\f106';
      }
    }
  }
}

/* SIDBAR MEDIAQUERIES ----------------------------------- */

@media (max-width: 1199px) {
  nav.side-navbar.shrinked,
  nav.side-navbar.collapsed {
    text-align: center;
    margin-left: -60px !important;
  }

  /* Reappearing the sidebar on toggle button click */
  nav.side-navbar.srinked,
  nav.side-navbar.collapsed {
    width: 60px !important;
    margin-left: 0 !important;
  }

  nav.side-navbar.srinked div.brand-small,
  nav.side-navbar.collapsed div.brand-small {
    display: block !important;
  }
  nav.side-navbar.srinked div.brand-big,
  nav.side-navbar.collapsed div.brand-big {
    display: none !important;
  }

  nav.side-navbar span.menu-label {
    display: none !important;
  }
  // nav.side-navbar.shrinked .sidebar-header h3 {
  //   display: none !important;
  // }
  // /* Downsize the navigation links font size */
  nav.side-navbar ul li a {
    padding: 15px 10px;
    text-align: center;
    font-size: 1.1em;
  }

  nav.side-navbar.shrinked ul ul a {
    padding: 10px !important;
  }
}
