section.forms p {
  font-size: 0.9em;
  color: #555;
}

section.forms form span,
section.forms form small {
  font-size: 0.8em;
  color: #999;
  font-weight: 300;
}

section.forms .line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #eee;
  margin: 30px 0;
}