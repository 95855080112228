.faq{
  color: $gray-800;
  .faq__question{
    color: $gray-800;
  }
  .faq__response{
    color: $gray-800;
  }
  .faq__list{
    margin-bottom: 1.5em;
    margin-top: 1.5em;
    padding-left: 17px;
    .faq__list-item{
      margin-bottom: 0;
      margin-top: .5rem;
      padding-left: 8px;
    }
  }
}