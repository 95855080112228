.autosuggest__input {
  border: 1px solid #e1e5ee;
  border-radius: 0.2rem !important;
  padding: 0.49rem 0.75rem;
  outline: none;
  position: relative;
  display: block;
  font-size: 0.875rem;
  color: #495057;
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #afb5c7;
  font-size: 0.9em;
  opacity: 1; /* Firefox */
}
.autosuggest__input.autosuggest__input-open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.autosuggest__results-container {
  position: relative;
  width: 100%;
}

.autosuggest__results {
  font-weight: 300;
  margin: 0;
  position: absolute;
  z-index: 10000001;
  width: 100%;
  border: 1px solid #e0e0e0;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background: white;
  padding: 0px;
  overflow: auto;
  max-height: 200px;
}

.autosuggest__results ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.autosuggest__results .autosuggest__results_item {
  cursor: pointer;
  padding: 10px 15px!important;
}

#autosuggest ul:nth-child(1) > .autosuggest__results_title {
  border-top: none;
}

.autosuggest__results .autosuggest__results_title {
  color: #6b757d;
  font-size: 11px;
  margin-left: 0;
  padding: 15px 13px 5px;
  border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results .autosuggest__results_item.autosuggest__results_item-highlighted {
  background-color: #e3f2fd;
}
