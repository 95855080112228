$white: #fff;
$gray-100: #f8f9fa;
$gray-200: #e9ecef;
$gray-300: #dee2e6;
$gray-400: #ced4da;
$gray-500: #adb5bd;
$gray-600: #6c757d;
$gray-700: #495057;
$gray-800: #343a40;
$gray-900: #212529;
$black: #000;
$primary-color: #5f3056; // Main Primary color */
$primary-color-lighter: #978395;
$primary-color-light: #796076;
$primary-color-dark: #733e69;
$primary-color-darker: #5f3056;
$secondary-color: #686d71;
$secondary-color-lighter: #aeb0b2;
$secondary-color-light: #85888b;
$secondary-color-dark: #4e5459;
$secondary-color-darker: #333a3f;
$accent-color: #d1a13d;
$accent-color-lighter: #ffdd95;
$accent-color-light: #eec264;
$accent-color-dark: #b1821f;
$accent-color-darker: #8b6007;
$primary: $primary-color;
$secondary: $secondary-color;
$navbar-background-color: #5f465c;
$footer-background-color: #686d71;
// As RGBa codes */
$rgba-primary: rgba(95, 70, 92, 1); // Main Primary color */
$rgba-primary-lighter: rgba(151, 131, 149, 1);
$rgba-primary-light: rgba(121, 96, 118, 1);
$rgba-primary-dark: rgba(115, 62, 105, 1);
$rgba-primary-darker: rgba(95, 48, 86, 1);
$rgba-secondary: rgba(104, 109, 113, 1);
$rgba-secondary-lighter: rgba(174, 176, 178, 1);
$rgba-secondary-light: rgba(133, 136, 139, 1);
$rgba-secondary-dark: rgba(78, 84, 89, 1);
$rgba-secondary-darker: rgba(51, 58, 63, 1);
$rgba-accent: rgba(215, 160, 42, 1);
$rgba-accent-lighter: rgba(255, 221, 149, 1);
$rgba-accent-light: rgba(238, 194, 100, 1);
$rgba-accent-dark: rgba(177, 130, 31, 1);
$rgba-accent-darker: rgba(139, 96, 7, 1);
$body-bg: #f8f7f2;
$body-color: $gray-600;
$font-family-sans-serif: 'Open Sans', sans-serif;

$small-font-size: 80%;
$text-muted: $gray-400;

$paragraph-margin-bottom: 0rem;
$border-radius: 0.4rem;
$border-radius-lg: 0.3rem;
$border-radius-sm: 0.2rem;

$font-size-base: 0.875rem;
$font-size-lg: ($font-size-base * 1.25);
$font-size-sm: ($font-size-base * 0.75);

$card-spacer-y: 0.375rem;
$card-spacer-x: 0.375rem;
$card-border-radius: 6px;
$card-inner-border-radius: 6px;
$card-cap-bg: $gray-100;
$card-border-width: 1px;
$card-border-color: $gray-400;

$line-height-base: 1.5;
$line-height-lg: 1.5;
$line-height-sm: 1.375;

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;
$input-btn-line-height: $line-height-base;

$input-btn-padding-y-sm: 0.2rem;
$input-btn-padding-x-sm: 0.4rem;
$input-btn-line-height-sm: $line-height-sm;

$input-btn-padding-y-lg: 0.5rem;
$input-btn-padding-x-lg: 1rem;
$input-btn-line-height-lg: $line-height-lg;

$zindex-dropdown: 1021;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-modal-backdrop: 1040;
$zindex-modal: 1050;
$zindex-popover: 1060;
$zindex-tooltip: 1070;
/*
$custom-control-indicator-size: 1.125rem;
$custom-control-indicator-bg: none;
$custom-control-indicator-bg-size: 80% 70%;
$custom-control-indicator-border-color: $primary-color;
$custom-control-indicator-border-width: 0.125rem;
$custom-control-indicator-checked-bg: $primary-color;
$custom-control-indicator-active-bg: $primary-color;
$custom-checkbox-indicator-border-radius: 0.125rem;
$custom-checkbox-indicator-icon-checked: svg-url(
  '<svg width="14" height="10" xmlns="http://www.w3.org/2000/svg"><path d="M5 10L0 5.1923l1.4-1.34615L5 7.3077 12.6 0 14 1.34615z" fill="#{$accent-color}" fill-rule="evenodd"/></svg>'
);
$custom-control-gutter: 0.75rem;
$custom-checkbox-indicator-indeterminate-bg: $custom-control-indicator-bg;
//$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate: none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-control-indicator-border-color;
$custom-radio-indicator-icon-checked: none;
*/
$alert-link-font-weight: normal;
$alert-margin-bottom: 0;
//
// Checkboxes (Awesome Bootstrap checkbox
// --------------------------------------------------
$font-family-icon: 'FontAwesome';
$fa-var-check: '\f00c';
$check-icon: $fa-var-check;
