a {
  color: $accent-color;
  text-decoration: none;
}

a:focus,
a:hover {
  color: $accent-color-lighter;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.1;
  color: inherit;
}

h1,
.h1 {
  font-size: 1.5rem;
  font-weight: 700;
}

h2,
.h2 {
  font-size: 1.3rem;
}

h3,
.h3 {
  font-size: 1.2rem;
}

h4,
.h4 {
  font-size: 1.1rem;
}

h5,
.h5 {
  font-size: 0.9rem;
}

h6,
.h6 {
  font-size: 0.8rem;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: 6rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-2 {
  font-size: 5.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-3 {
  font-size: 4.5rem;
  font-weight: 300;
  line-height: 1.1;
}

.display-4 {
  font-size: 3.5rem;
  font-weight: 300;
  line-height: 1.1;
}

hr {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: normal;
}
.x-small {
  font-size: 60%;
  font-weight: normal;
}
mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.blockquote {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  border-left: 5px solid $accent-color;
}

.blockquote-footer {
  color: #868e96;
}

.blockquote-footer::before {
  content: '\2014 \00A0';
}
