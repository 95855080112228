/*====== Work Amount Box ======*/

.work-amount .chart {
  max-width: 180px;
  max-height: 180px;
  margin: 40px auto;
  position: relative;
}

.work-amount .chart .text {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.work-amount .chart strong {
  font-size: 1.5em;
}

.work-amount .chart span {
  color: #999;
  font-weight: 300;
}

.work-amount li span {
  font-size: 0.85em;
  margin-bottom: 10px;
  color: #777;
  display: block;
}

.work-amount li span::before {
  content: '';
  display: inline-block;
  margin-right: 10px;
  width: 7px;
  height: 7px;
  line-height: 7px;
  background: #85b4f2;
  border-radius: 50%;
}

/*====== Client Profile Box ======*/

.client .client-avatar {
  width: 100px;
  height: 100px;
  margin: 0 auto;
  position: relative;
}

.client .client-avatar .status {
  content: '';
  display: block;
  width: 18px;
  height: 18px;
  border: 3px solid #fff;
  border-radius: 50%;
  position: absolute;
  right: 4px;
  bottom: 4px;
}

.client .client-title {
  margin-top: 20px;
}

.client .client-title h3 {
  font-weight: 500;
  color: #555;
}

.client .client-title span {
  font-size: 0.9em;
  color: #aaa;
  display: block;
}

.client .client-title a {
  padding: 2px 30px;
  border-radius: 40px;
  background: #54e69d;
  color: #fff;
  margin-top: 5px;
  font-size: 0.9em;
  text-decoration: none;
}

.client .client-title a:hover {
  background: #85b4f2;
}

.client .client-info {
  margin-top: 20px;
}

.client .client-info strong {
  font-weight: 700;
}

.client .client-social {
  margin-top: 20px;
}

.client .client-social a {
  color: #aaa;
}

/*====== Total Overdue Box ======*/

.overdue .chart canvas {
  width: 100% !important;
  z-index: 1;
}

.overdue .card {
  margin-bottom: 0;
}

.overdue .card-body {
  padding: 20px;
}

.overdue .card-body small {
  font-weight: 300;
  color: #aaa;
}

.overdue .card-body h3 {
  margin-bottom: 5px;
}

.overdue .number {
  font-size: 1.8em;
  font-weight: 400;
  color: #555;
  margin: 35px 0;
}