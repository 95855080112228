.daily-feeds .item {
  padding: 20px;
  border-bottom: 1px solid #eee;
}

.daily-feeds .feed-profile {
  max-width: 50px;
  min-width: 50px;
  margin-right: 10px;
}

.daily-feeds h5 {
  margin-bottom: 0;
  color: #555;
}

.daily-feeds span {
  font-size: 0.8em;
  color: #999;
}

.daily-feeds .full-date {
  font-size: 0.85em;
  color: #aaa;
  font-weight: 300;
}

.daily-feeds .CTAs {
  margin-top: 5px;
}

.daily-feeds .CTAs a {
  font-size: 0.7em;
  padding: 3px 8px;
  margin-right: 5px;
}

.daily-feeds .CTAs a i {
  margin-right: 5px;
}

.daily-feeds .quote {
  background: #fafafa;
  margin-top: 5px;
  border-radius: 0;
  padding: 15px;
  margin-left: 60px;
}

.daily-feeds .quote small {
  font-size: 0.75em;
  color: #777;
}

.daily-feeds .date {
  font-size: 0.9em;
  color: #aaa;
}
