section.charts div[class*='col-'] {
  margin-bottom: 30px;
}

section.charts header {
  padding: 30px 0;
}

section.charts div[class*='col-'] {
  margin-bottom: 0;
}

.line-chart-example .card-block {
  padding: 20px;
}

/*
* ==========================================================
*     POLAR CHART
* ==========================================================
*/

/*
* ==========================================================
*     POLAR CHART
* ==========================================================
*/

.polar-chart-example canvas {
  max-width: 250px;
  max-height: 250px;
  margin: 20px auto;
}

/*
* ==========================================================
*     PIE CHART
* ==========================================================
*/

.pie-chart-example canvas {
  max-width: 250px !important;
  max-height: 250px !important;
  margin: 15px auto;
}

/*
* ==========================================================
*     RADAR CHART
* ==========================================================
*/

.radar-chart-example canvas {
  max-width: 250px;
  max-height: 250px;
  margin: 20px auto;
}

/* FORMS MEDIAQUERIES -------------------------- */

@media (max-width: 991px) {
  section.charts h2 {
    font-size: 1rem;
  }
}
