/*
* ==========================================================
*     LOGIN PAGE
* ==========================================================
*/

.page-login {
  position: relative;
  height: calc(100vh - 36px);
  background-color: #e2e2e2;
  overflow-y: scroll;
}

.page-login::before {
  content: "";
  width: 100%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 0;
}

.page-login .container {
  min-height: 100vh;
  z-index: 99;
  padding: 20px;
  position: relative;
}

.page-login .form-holder {
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 50px;
}

.page-login .form-holder .info,
.page-login .form-holder .form {
  min-height: 50vh;
  padding: 30px;
  height: 100%;
  border-radius: 10px;
}

.page-login .form-holder .info {
  background: $rgba-primary;
  color: #fff;
}

.page-login .form-holder .info h1 {
  font-size: 2.5em;
  font-weight: 600;
}

.page-login .form-holder .info p {
  font-weight: 300;
}

.page-login .form-holder .form .form-group {
  position: relative;
  margin-bottom: 15px;
}

.page-login .form-holder .form .content {
  width: 100%;
}

.page-login .form-holder .form form {
  width: 100%;
  margin-top: 40px;
  label {
    color: $white;
  }
}

.page-login .form-holder .form .logo img {
  width: 140px;
}

.page-login .form-holder .form #login,
.page-login .form-holder .form #register {
  margin-bottom: 20px;
  cursor: pointer;
}

.page-login .form-holder .form a.forgot-pass,
.page-login .form-holder .form a.signup {
  font-size: 0.9em;
  color: $primary-color-darker;
}

.page-login .form-holder .form small {
  color: #aaa;
}

.page-login .form-holder .form .terms-conditions label {
  cursor: pointer;
  color: #aaa;
  font-size: 0.9em;
}

.page-login .copyrights {
  width: 100%;
  z-index: 0;
  position: absolute;
  bottom: 10px;
  left: 0;
  color: #fff;
}

@media (max-width: 991px) {
  .page-login .info,
  .page-login .form {
    min-height: auto !important;
  }
  .page-login .info {
    padding-top: 100px !important;
    padding-bottom: 100px !important;
  }
}
