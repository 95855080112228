@charset "UTF-8";
@import 'mixins';
$icomoon-font-path: '../fonts' !default;
$fa-font-path: '~font-awesome/fonts';

@import 'variables';
@import 'font';
@import '~bootstrap/scss/bootstrap.scss';
@import 'sizing';
@import 'dropzone.scss';
@import 'croppie.scss';
@import 'vue-multiselect.scss';
@import 'vue-treeselect.scss';
@import 'fullcalendar-core.scss';
@import 'fullcalendar-resource-timeline.scss';
@import 'fullcalendar-timeline.scss';
@import 'fullcalendar-timegrid.scss';
@import 'vue-autosuggest.scss';
@import 'vue2-datepicker.scss';
@import 'vue-slider.scss';
@import 'leaflet.scss';
@import 'leaflet-routing-machine.scss';
@import 'toastr.scss';
@import 'leaflet-marker-cluster.scss';
/*
* ==========================================================
*     GENERAL STYLES
* ==========================================================
*/

@import 'ui/general';

/*
* ==========================================================
*     SIDEBAR
* ==========================================================
*/

@import 'partials/sidebar';
/*
* ==========================================================
*     MAIN NAVBAR
* ==========================================================
*/

@import 'partials/navbar';
/*
* ==========================================================
*     DASHBOARD COUNTS SECTION
* ==========================================================
*/

@import 'pages/dashboard';
/*
* ==========================================================
*     PROJECTS SECTION
* ==========================================================
*/

@import 'pages/projects';
/*
* ==========================================================
*     CLIENT SECTION
* ==========================================================
*/

@import 'pages/client';
/*
* ==========================================================
*     FEEDS SECTION
* ==========================================================
*/

@import 'ui/feeds';
/*
* ==========================================================
*     UPDATES SECTION
* ==========================================================
*/

/*====== Recent Updates Box ======*/

@import 'partials/recent-updates';
/*====== Daily Feeds Box ======*/

@import 'partials/daily-feeds';
/*====== Recent Activities Box ======*/

@import 'partials/recent-activities';
/*
* ==========================================================
*     FOOTER
* ==========================================================
*/

@import 'partials/footer';
/*
* ==========================================================
*     CHART
* ==========================================================
*/
@import 'chart';
/*
* ==========================================================
*     FORM ELEMENTS
* ==========================================================
*/

@import 'ui/form-elements';
/*
* ==========================================================
*     FORM PAGE
* ==========================================================
*/
@import 'pages/forms';

/* =========================================
   THEMING OF BOOTSTRAP COMPONENTS
   ========================================= */
/*
 * 1. NAVBAR
 */
//@import 'ui/navbar';
/*
 * 2. BUTTONS
 */
@import 'ui/button';
/*
 * 3. TYPE
 */
@import 'ui/type';
/*
 * 4. PAGINATION
 */
@import 'ui/pagination';
/*
* 5. UTILITIES
*/
//@import 'ui/utilities';

/* =========================================
   Pages
   ========================================= */

@import 'pages/login';
@import 'pages/register';
@import 'pages/support';
