.project .row {
  margin: 0;
  padding: 15px 0;
  margin-bottom: 15px;
}

.project div[class*='col-'] {
  border-right: 1px solid #eee;
}

.project .text h3 {
  margin-bottom: 0;
  color: #555;
}

.project .text small {
  color: #aaa;
  font-size: 0.75em;
}

.project .project-date span {
  font-size: 0.9em;
  color: #999;
}

.project .image {
  max-width: 50px;
  min-width: 50px;
  height: 50px;
  margin-right: 15px;
}

.project .time,
.project .comments,
.project .project-progress {
  color: #999;
  font-size: 0.9em;
  margin-right: 20px;
}

.project .time i,
.project .comments i,
.project .project-progress i {
  margin-right: 5px;
}

.project .project-progress {
  width: 200px;
}

.project .project-progress .progress {
  height: 4px;
}

.project .card {
  margin-bottom: 0;
}

/* PROJECTS SECTION ------------------------------------- */

@media (max-width: 991px) {
  .project .right-col {
    margin-top: 20px;
    margin-left: 65px;
  }
  .project .project-progress {
    width: 150px;
  }
}

@media (max-width: 480px) {
  .project .project-progress {
    display: none;
  }
}